<template>
  <b-modal
    id="edit-request-modal"
    ref="edit-request-modal"
    modal-class="modal-primary"
    title="แก้ไขข้อมูลเชื่อมต่อ Sandbox"
    hide-footer
  >
    <validation-observer ref="editRequestForm">
      <b-form @submit.prevent="onSubmit">
        <validation-provider
          v-slot="{ errors }"
          name="callbackUrl"
          rules=""
        >
          <b-form-group
            label="Callback URL"
            label-for="callbackUrl"
          >
            <b-form-input
              id="callbackUrl"
              v-model="formData.callbackUrl"
              :state="errors[0] ? false : null"
            />
            <b-form-text>หมายเหตุ การเปลี่ยน Callback คุณจำเป็นจะต้องทดสอบ Callback ใหม่ทั้งหมด</b-form-text>
          </b-form-group>
        </validation-provider>
        <label for="input-none">Whitelist IP</label>
        <b-row>
          <b-col
            v-for="(ip, index) in whitelist"
            :key="index"
            cols="6"
            class="pb-1"
          >
            <b-form-input
              v-model="whitelist[index]"
              :placeholder="`IP Address ${index + 1}`"
            />
          </b-col>
        </b-row>
        <br>
        <div class="d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="hideModal"
          >
            ปิด
          </b-button>
          <b-overlay :show="isLoading">
            <b-button
              variant="primary"
              type="submit"
            >
              ยืนยัน
            </b-button>
          </b-overlay>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    requestDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {
        id: '',
        callbackUrl: '',
        whitelistIp: '',
      },
      whitelist: ['', '', '', '', '', ''],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.request.isEditingRequest,
    }),
  },
  watch: {
    requestDetail(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['editRequest']),
    setData() {
      if (this.requestDetail.id) {
        const data = JSON.parse(JSON.stringify(this.requestDetail))
        this.formData.id = this.id
        this.formData.callbackUrl = data.callbackUrl || ''
        if (data.whitelistIp.length > 0) {
          data.whitelistIp
            .split(',')
            .forEach((ip, index) => (this.whitelist[index] = ip))
        }
      } else {
        this.formData = {
          id: '',
          callbackUrl: '',
          whitelistIp: '',
        }
      }
    },
    hideModal() {
      this.$bvModal.hide('edit-request-modal')
    },
    onSubmit() {
      this.$refs.editRequestForm.validate().then(async (success) => {
        if (success) {
          this.formData.whitelistIp = this.whitelist
            .filter((ip) => !!ip)
            .join(',')
          this.editRequest(this.formData)
          this.hideModal()
        }
      })
    },
  },
}
</script>
